import React from 'react';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import {getNonceProvider} from "../../utils/helpersFunctions";
import createCache from "@emotion/cache";
import { CacheProvider } from '@emotion/react';

// This injects the CSS directly under your CacheProvider
/*const GlobalStyles = () => (
  <Global
    styles={css`
      @import url('antd/dist/antd.css');
      @import url('react-js-cron/dist/styles.css');
    `}
  />
);*/

const cache = createCache({
    key: 'react-js-cron',
    nonce: getNonceProvider()
});


// Define English and Spanish locale configurations directly
const enLocale = {
  everyText: 'Every',
  emptyMonths: 'All months',
  emptyMonthDays: 'All days of the month',
  emptyWeekDays: 'All days of the week',
  emptyHours: 'Every hour',
  emptyMinutes: 'Every minute',
  emptyMinutesForHourPeriod: 'Every minute',
  yearOption: 'Year',
  monthOption: 'Month',
  weekOption: 'Week',
  dayOption: 'Day',
  hourOption: 'Hour',
  minuteOption: 'Minute',
  rebootOption: 'Reboot',
  prefixPeriod: 'Every',
  prefixEvery: 'Every',
  prefixIncrement: 'Every',
  prefixAnd: 'and',
  prefixAt: 'at',
  suffixPeriod: '',
  suffixIncrement: '',
  errorInvalidCron: 'Invalid cron expression',
  clearButtonText: 'Clear'
};

const esLocale = {
  everyText: 'Cada',
  emptyMonths: 'Todos los meses',
  emptyMonthDays: 'Todos los días del mes',
  emptyWeekDays: 'Todos los días de la semana',
  emptyHours: 'Cada hora',
  emptyMinutes: 'Cada minuto',
  emptyMinutesForHourPeriod: 'Cada minuto',
  yearOption: 'Año',
  monthOption: 'Mes',
  weekOption: 'Semana',
  dayOption: 'Día',
  hourOption: 'Hora',
  minuteOption: 'Minuto',
  rebootOption: 'Reiniciar',
  prefixPeriod: 'Cada',
  prefixEvery: 'Cada',
  prefixIncrement: 'Cada',
  prefixAnd: 'y',
  prefixAt: 'a las',
  suffixPeriod: '',
  suffixIncrement: '',
  errorInvalidCron: 'Expresión cron no válida',
  clearButtonText: 'Limpiar'
};

const CronComponent = ({ intl, value,setValue,allowedPeriods,allowedDropdowns }) => {
  // Choose the appropriate locale based on the intl.locale value
  const cronLocale =  enLocale;

  return(
    <CacheProvider value={cache}>
      {/*<GlobalStyles />*/}
      <Cron allowEmpty="never" value={value} allowedPeriods={allowedPeriods} allowedDropdowns={allowedDropdowns} defaultPeriod = {'month'} setValue={setValue} locale={cronLocale} />
    </CacheProvider>
  )
};

export default CronComponent;
