/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

// core components
import Main from "layouts/Main.jsx";
import ReactGA from 'react-ga';
import "assets/css/react-js-cron-antd.css";
import '../src/utils/i18n';
import {GOOGLE_ANALYTICS_TRACKING} from "./variables/apiConstants";
import "assets/css/material-dashboard-react.css?v=1.7.0";
import "assets/css/react-datetime.css";
import '@grapecity/wijmo.styles/wijmo.css';

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING);

// const container = document.getElementById('root');
//
// if (container) {
//     const root = ReactDOM.createRoot(container);
//     root.render(<Main />);
// }

ReactDOM.render(
    <Main/>,
    document.getElementById("root")
);