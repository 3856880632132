import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import TextIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import CustomCron from "../../../components/CustomCron"

// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent, enableComponent, isEmpty, isSuccessfulCreate,
    isSuccessfulRequest, nowMomentDate, removeData, saveData, selectValidation,
} from "../../../utils/helpersFunctions";
import {DIRECT_DEBIT_TYPE_ID, NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {
    getMemberGroupsByCompany
} from "../../../services/memberService";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import Datetime from "react-datetime";
import {addDirectDebit, refreshPrivateServiceToken} from "../../../services/directDebitService";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import HeaderForm from "../../../components/CustomComponents/HeaderForm";
import {formTitles} from "../../../utils/statusHelpers";
import {Backdrop, CircularProgress} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {getData} from "../../../utils/helpersFunctions.js";
import { TimePicker } from "antd";
import moment from "moment";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch.jsx";
import makeAnimated from "react-select/animated";
import { getCompanyInformation } from "../../../services/accountService";

const animatedComponents = makeAnimated();

class DirectDebitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debitId: '',
            name: '',
            memberGroup: '',
            emailNotifications: true,
            memberGroupOptions: [],
            cronTimer: "",
            enabled: true,
            cronPeriod: "month",
            allowedDropdowns: ["period", "months", "month-days"],
            debitType:{
                label:"Manual",
                value:"1",
            },
            debitTypeOptions:[
                {
                    label: "Manual",
                    value: "1",
                },
                {
                    label: "Automatic",
                    value: "2",
                },
            ],
            debitRecursionCron:"",
            date: nowMomentDate(),
            retryDebitToken: 0,
            color: 'primary',
            notificationMessage: '',
            icon: NotificationSuccessIcon,
            notification: false,
            isLoading: false,
            disableField: false,
            isEditing: false,
            timezone: "",
            timezoneOptions: ""
        };
    }


    componentDidMount() {
        const { t, routeProps, match } =  this.props;

        if(match.params.id){
            const debit = getData("debit");
            const [minute,hour, dayOfMonth, month, dayOfWeek, year] = debit.cronSchedule.split(" ");
            const selectedPeriod = dayOfWeek !== "*" && dayOfWeek !== "?" ? "week" : "month"; // Default to monthly
            this.setState({ 
                name:debit.name,
                debitId: debit.id,
                debitType: debit.debitType == "Manual" ? { label:"Manual", value:"1" }  : { label:"Automatic", value: "2" },
                debitRecursionCron : debit.debitType != "Manual" ? this.awsCronToRegular(debit.cronSchedule) : "",
                cronTimer: moment({hour: `${hour && hour == "*" ? "00" : hour }`, minute: `${minute && minute == "*" ? "00" : minute }`}),
                date: new Date(debit.paymentExpiringBefore),
                emailNotifications: debit.notificationEnabled === "true",
                enabled: debit.enabled == "true",
                isEditing: true,
                allowedDropdowns: selectedPeriod == "month" ? ["period", "months", "month-days"] : ["period", "months", "month-days", "week-days"],
                cronPeriod: selectedPeriod
            });
        }

        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        this.initRequest();
    }



    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData = () => {
        this.setState({
            name: '',
            memberGroup: '',
        });
    };

    changeGroupSelect = (memberGroup) => {
        this.setState({ memberGroup });
    };

    changeDebitTypeSelect = (debitType) => {
        this.setState({ debitType });
    }
    
    handleCronValueChange = (debitRecursionCron,{ selectedPeriod:cronPeriod })=>{
        
        this.setState({
                allowedDropdowns: cronPeriod == "month" 
                                    ? ["period", "months", "month-days"]
                                    : ["period", "months", "month-days", "week-days"]
        });

        this.setState({debitRecursionCron, cronPeriod});
    }

    handleTimerValueChange = (cronTimer)=>{
        this.setState({ cronTimer });
    }

    convertToAwsCron = (cronExpression) => {
        const parts = cronExpression.split(' ');
    
        if (parts.length !== 5 && parts.length !== 6) {
            throw new Error('Invalid cron expression. Must have 5 or 6 fields.');
        }
    
        const [minutes, hours, dayOfMonth, month, dayOfWeek, year = '*'] = parts;
    
        // AWS requires either Day-of-Month or Day-of-Week to be "?"
        let dayOfMonthAWS = dayOfMonth;
        let dayOfWeekAWS = dayOfWeek;
    
        // Handle case where Day-of-Week has step value "*/2"
        if (dayOfWeek.includes('*/'))
        {
            // If Day-of-Week contains "*/2", we must ensure Day-of-Month is "?" (because AWS cannot have both *)
            dayOfMonthAWS = '?';
        }
    
        // Handle case for range with step value (e.g., "1-5/2")
        if (dayOfWeek.includes('-') && dayOfWeek.includes('/'))
        {
            dayOfMonthAWS = '?'; // Ensure Day-of-Month is "?" for ranges with step values in Day-of-Week
        }
    
        // Handle case for multiple values in Day-of-Week (e.g., "1,3")
        if (dayOfWeek.includes(',') && dayOfMonth === '*')
        {
            dayOfMonthAWS = '?'; // Multiple days in the week requires Day-of-Month to be "?"
            if(dayOfWeek.includes('/')){
                const [startEndDate,dividerNum] = dayOfWeek.split("/");
                const [startDate,endDate] = startEndDate.split(",");
                dayOfWeekAWS = `${ parseInt(startDate) + 1 },${ parseInt(endDate) + 1 }/${dividerNum}`;
            }
            else{
                const [startDate,endDate] = dayOfWeek.split(",");
                dayOfWeekAWS = `${ parseInt(startDate) + 1 },${ parseInt(endDate) + 1 }`;
            }
        } 
        else if(dayOfWeek.includes('-') && dayOfMonth === '*')
        {
            dayOfMonthAWS = '?'; // Multiple days in the week requires Day-of-Month to be "?"
            if(dayOfWeek.includes('/')){
                const [startEndDate,dividerNum] = dayOfWeek.split("/");
                const [startDate,endDate] = startEndDate.split("-");
                dayOfWeekAWS = `${ parseInt(startDate) + 1 }-${ parseInt(endDate) + 1 }/${dividerNum}`;
            }
            else{
                const [startDate,endDate] = dayOfWeek.split("-");
                dayOfWeekAWS = `${ parseInt(startDate) + 1 }-${ parseInt(endDate) + 1 }`;
            }
        }
        else if (dayOfWeek !== '*' && dayOfMonth === '*')
        {
            dayOfWeekAWS = parseInt(dayOfWeek) + 1;
            dayOfMonthAWS = '?';
        }
        else if (dayOfMonth === '*' && dayOfWeek === '*')
        {
            dayOfMonthAWS = '?'; // Both "*" can't be present at the same time
        }
        else if (dayOfMonth !== '?' && dayOfMonth !== '*' && dayOfWeek !== '?')
        {
            // If Day-of-Month is explicitly set (e.g., "15"), Day-of-Week must be "?"
            dayOfWeekAWS = '?';
        } 
        else if (dayOfMonth === '?' && dayOfWeek === '?')
        {
            throw new Error("Invalid cron expression: both Day-of-Month and Day-of-Week cannot be '?'.");
        }

        const [ hour, minute ] = moment(this.state.cronTimer._d).format("HH:mm").split(":");
    
        // Return the converted AWS cron expression
        return `${minute} ${hour} ${dayOfMonthAWS} ${month} ${dayOfWeekAWS} ${year}`;
    };

    awsCronToRegular = (awsCron) => {
        if (!awsCron || awsCron.trim().length === 0) return;
    
        const parts = awsCron.split(" ");
        
        if (parts.length !== 6)
        {
            throw new Error("Invalid AWS cron expression. AWS requires 6 fields.");
        }
    
        let [minute, hour, dayOfMonth, month, dayOfWeek, year] = parts;
    
        // AWS uses "?" in either Day-of-Month or Day-of-Week
        // We need to correctly translate it back to standard cron
        if (dayOfMonth === "?" && dayOfWeek !== "*")
        {
            dayOfMonth = "*"; // If AWS cron used "?", it means it was a weekday-based cron

            if(!dayOfWeek.includes("*/") && !dayOfWeek.includes("-") && !dayOfWeek.includes("/"))
            {
                dayOfWeek = `${parseInt(dayOfWeek) - 1}`;
            }
        }

        if (dayOfWeek === "?" && dayOfMonth !== "*")
        {
            dayOfWeek = "*"; // If AWS cron used "?", it means it was a date-based cron
        }
    
        // Handle the "*/N" case in Day-of-Week field
        if (dayOfWeek.includes("*/") && dayOfMonth === "?")
        {
            // If the Day-of-Week has a step value (e.g., */2), we keep the same format
            dayOfWeek = dayOfWeek.replace("*/", "");
        }
        else if (dayOfWeek.includes(",") )
        {
            if(dayOfWeek.includes('/')){
                const [startEndDate,dividerNum] = dayOfWeek.split("/");
                const [startDate,endDate] = startEndDate.split(",");
                dayOfWeek = `${ parseInt(startDate) - 1 },${ parseInt(endDate) - 1 }/${dividerNum}`;
            }
            else{
                const [startDate,endDate] = dayOfWeek.split(",");
                dayOfWeek = `${ parseInt(startDate) - 1 },${ parseInt(endDate) - 1 }`;
            }
        }
        else if (dayOfWeek.includes("-"))
        {
            if(dayOfWeek.includes('/')){
                const [startEndDate,dividerNum] = dayOfWeek.split("/");
                const [startDate,endDate] = startEndDate.split("-");
                dayOfWeek = `${ parseInt(startDate) - 1 }-${ parseInt(endDate) - 1 }/${dividerNum}`;
            }
            else{
                const [startDate,endDate] = dayOfWeek.split("-");
                dayOfWeek = `${ parseInt(startDate) - 1 }-${ parseInt(endDate) - 1 }`;
            }
        }
    
        // Handle one-time trigger case: If year is specified and dayOfMonth is not "*", it's a one-time event
        if (year !== "*" && dayOfMonth !== "*" && dayOfWeek === "?")
        {
            return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`; // Keep year field for one-time trigger
        }

        return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
    };

    handleChangeDate = (date) => {
        this.setState({ date: date } );
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh) {
                    this.handleGoDetails({
                        id: this.state.debitId,
                        name: this.state.name,
                        paymentExpiringBefore: this.state.date.valueOf().toString()
                    });
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    saveDebit = async () => {
        const { t, match } = this.props;
        const { name, memberGroup, date, emailNotifications } = this.state;

        if (!selectValidation(memberGroup) && name !== '') {
            disableComponent(this, "disableField");

            let dataParameters = {
                "debitId": match.params.id || "",
                "name": name,
                "groupId": memberGroup.value,
                "paymentExpiringBeforeDate": date.valueOf().toString(),
                "emailNotifications": emailNotifications,
                "debitType": this.state.debitType.label,
                "enabled": this.state.enabled,
                "cronSchedule": "",
                "timezone": ""
            };

            if(this.state.debitType.value == 2){
                
                if(this.state.debitRecursionCron == "")
                {
                    this.showNotification("Please fill the concurence for the automatic debit.", "danger",NotificationFailedIcon, null);
                    enableComponent(this, "disableField");
                    return;
                }
                
                if(this.state.timezone == "")
                {
                    this.showNotification("Please fill the timezone automatic debit.", "danger",NotificationFailedIcon, null);
                    enableComponent(this, "disableField");
                    return;
                }

                if(this.state.cronTimer == "")
                {
                    this.showNotification("Please specify the automatic debit time.", "danger",NotificationFailedIcon, null);
                    enableComponent(this, "disableField");
                    return;
                }

                const [minutes, hours, dayOfMonth, month, dayOfWeek, year = '*'] = this.state.debitRecursionCron.split(' ');

                const newCronSchedule  = `${minutes} ${hours} ${this.state.cronPeriod != "month" && dayOfMonth != "*" ? "*" : dayOfMonth} ${month} ${ this.state.cronPeriod == "month" && dayOfWeek!="*" ? "*" : dayOfWeek} ${year}`;
                
                dataParameters.cronSchedule = this.convertToAwsCron(newCronSchedule);

                dataParameters.timezone = this.state.timezone.label;
            }

            this.setState({ retryDebitToken: this.state.retryDebitToken + 1 }, ()=> {
                addDirectDebit(dataParameters).then(response => {
                    if (isSuccessfulCreate(response)) {
                        const {data} = response;
                        if (data.code != null && data.code.code === 500) {
                            this.setState({ debitId: data.id, retryDebitToken: 0 });
                            this.showNotification(t(!match.params.id  ? 'SUCCESS_DEBIT_DEFINITION_CREATION' : 'SUCCESS_DEBIT_DEFINITION_UPDATE'), "primary", NotificationSuccessIcon, true);
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.showNotification(data.error.message, "danger",
                                NotificationFailedIcon, null);
                        }
                    } else {
                        this.setState({ retryDebitToken: 0 });
                        this.showNotification(response.data.error.message, "danger",
                            NotificationFailedIcon, null);
                    }

                    enableComponent(this, "disableField");
                }).catch(e => {
                    enableComponent(this, "disableField");
                    this.setState({ isLoading: false });
                    if (!isEmpty(e.response)) {
                        if (this.state.retryDebitToken <= 2) {
                            refreshPrivateServiceToken(e.response, this.saveDebit, (errorResponse) => {
                                this.showNotification(errorResponse.data.error.message, "danger",
                                    NotificationFailedIcon, null);
                            });
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.showNotification(e.response.data.error.message, "danger",
                                NotificationFailedIcon, null);
                        }
                    } else {
                        this.setState({ retryDebitToken: 0 });
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });
            });
        } else {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationFailedIcon, null);
        }
    }




    initRequest = () => {
        this.setState({ isLoading: true });

        let parameters = {
            "page": 0,
            "pageSize": 100,
            "filterId": DIRECT_DEBIT_TYPE_ID,
            "status": -1 // Without status filter
        };


        getMemberGroupsByCompany(parameters)
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    let values = [];

                    if (response.data !== null && response.data !== undefined) {
                        values = response.data.content.map(item => {
                            return { label: item.name, value: item.uuid }
                        })
                    }

                    const debit = getData("debit");
                    let newState = {
                        memberGroupOptions: values
                    };

                    if(debit && debit.groupId){
                        const debitGroup = values.filter((item) => item.value == debit.groupId);
                        newState.memberGroup = debitGroup[0];
                    }

                    //this.setState(newState);
                    this.fetchTimezoneData(newState);
                } else {
                    this.setState({ isLoading: false });
                    this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger",
                        NotificationFailedIcon, null);

                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null)
                }
            });

        
    }

    fetchTimezoneData(newState){
        //fetched the timezone values
        getCompanyInformation()
        .then((response) => {
        
            try {
                let key = 0;
                newState.timezoneOptions = response.data.timeZones.map(item => {
                    key++;
                    return { value: key , label: item };
                });

                const defaultTimezone = newState.timezoneOptions.filter((item)=> item.label == response.data.currentTimeZone);
                if(defaultTimezone){
                    newState.timezone = defaultTimezone[0];
                }

                const debit = getData("debit");
                
                if(debit && debit.timezone && debit.timezone != ""){
                    const debitTimezone = newState.timezoneOptions.filter((item) => item.label == debit.timezone);
                    newState.timezone = debitTimezone[0];
                }

                newState.isLoading = false;
                this.setState(newState);
            } catch (e) {
                console.log("Something went wrong");
            }

        })
        .catch((err) => {
            this.setState({ isLoading: false} );
            if (err.response) {
                this.showNotification(err.response.data.message, "danger",
                    NotificationFailedIcon, null);

                validateSession(err.response, this.props.history);
            } else {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                    NotificationFailedIcon, null)
            }
        });
    }


    handleGoDetails = (row) => {
        removeData("syncJobId");
        removeData("isExecuted");
        removeData("syncSummary");
        removeData("debitExecution");
        saveData("debit", row);
        saveData("syncJobAvailable", true);

        if(this.state.debitType.value == 1){
            this.props.history.replace("/admin/debit/" + row.id + "/prepare-sync");            
        }

        if (this.state.debitType.value == 2){
            this.props.history.replace("/admin/debits");
        }
    };

    updateGroup = () => {}

    handleChangEmailNotifications = (prevEmailNotifications) => {
        this.setState({
            emailNotifications: !prevEmailNotifications
        });
    };

    handleEnabledChange = (prevEnabled) => {
        this.setState({
            enabled: !prevEnabled
        });
    };

    changeTimeZoneSelect = timezone => {
        this.setState({ timezone } );
    };



    render() {
        const { classes, t } = this.props;
        const { disableField, isLoading, emailNotifications, enabled } = this.state;
        return (
            <div>
                <Backdrop className={classes.backdrop} open={disableField || isLoading}>
                    <CircularProgress color={"inherit"} />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={false} sm={2} md={2} lg={3}/>
                    <GridItem xs={12} sm={8} md={8} lg={6}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <GridContainer>
                                            <GridItem xs={false} sm={2} md={3} lg={3}/>
                                            <GridItem xs={12} sm={8} md={6} lg={6}>
                                                <div className={classes.headerFormContainer}>
                                                    <HeaderForm title={t(formTitles[ this.state.debitId != "" ? 1 : 0]) + t('DIRECT_DEBIT_TEXT')} description={""} textAlign={"center"}/>
                                                </div>
                                            </GridItem>
                                            <GridItem xs={false} sm={2} md={3} lg={3}/>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            value={this.state.name}
                                            onChange={this.handleChangeValues}
                                            labelText={t('NAME_TEXT')}
                                            id="name"
                                            name="name"
                                            autoFocus={true}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <TextIcon className={classes.colorIcon}/>
                                                </InputAdornment>
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.formControl}>
                                            <InputLabel htmlFor="type" className={classes.selectTitle}>
                                                {t('TH_MEMBER_GROUP')}
                                            </InputLabel>
                                            <Select
                                                value={this.state.memberGroup}
                                                onChange={this.changeGroupSelect}
                                                isDisabled={this.state.disableField}
                                                isLoading={this.state.disableField}
                                                options={this.state.memberGroupOptions}
                                                placeholder={t('SELECT_TEXT')}
                                                closeMenuOnSelect={true}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: primaryColor[0],
                                                    },
                                                })}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.defaultTimeContainer}>
                                            <InputLabel className={classes.selectTitle}>
                                                {t('PAYMENT_EXPIRING_BEFORE')}
                                            </InputLabel>
                                            <Datetime
                                                value={this.state.date}
                                                onChange={this.handleChangeDate}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat={"HH:mm:ss"}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControlLabel
                                            className={classes.checkboxLabel}
                                            control={
                                                <Checkbox
                                                    value={emailNotifications}
                                                    checked={emailNotifications}
                                                    onClick={() => this.handleChangEmailNotifications(emailNotifications)}
                                                    classes={{
                                                        checked: classes.checkBoxChecked,
                                                        root: classes.checkBox
                                                    }}
                                                />}
                                            label={t('EMAIL_NOTIFICATIONS_DEBIT')}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.selectControlMax}>
                                            <InputLabel htmlFor="debitType" className={classes.selectTitle}>
                                                {t('TYPE_TEXT')}
                                            </InputLabel>
                                            <Select
                                                value={this.state.debitType}
                                                onChange={this.changeDebitTypeSelect}
                                                options={this.state.debitTypeOptions}
                                                placeholder={t('SELECT_TEXT')}
                                                closeMenuOnSelect={true}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: primaryColor[0],
                                                    },
                                                })}
                                            />
                                        </div>
                                    </GridItem>
                                    {
                                    this.state.debitType.label != "Manual"
                                    ?
                                    <>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{color: "#ff4d4f",marginBottom: "20px",textAlign: "center"}}><b>{t('NOTE_TEXT')}:</b> {t('AUTOMATIC_DEBIT_CREATION_WAARNING')} </div>
                                            <div>
                                                <InputLabel htmlFor="debitType" className={classes.selectTitle}>
                                                    Select period
                                                </InputLabel>
                                                <CustomCron
                                                    value={this.state.debitRecursionCron}
                                                    setValue={this.handleCronValueChange}
                                                    allowedPeriods={["month", "week"]}
                                                    allowedDropdowns={this.state.allowedDropdowns}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: "20px"}}>
                                                <InputLabel htmlFor="timeZones" className={classes.selectTitle}>
                                                    {t('TIME_ZONES_TEXT')}
                                                </InputLabel>
                                                <Select
                                                    value={this.state.timezone}
                                                    onChange={this.changeTimeZoneSelect}
                                                    components={animatedComponents}
                                                    options={this.state.timezoneOptions}
                                                    placeholder={t('SELECT_TEXT')}
                                                    isClearable={true}
                                                    maxMenuHeight={130}
                                                    closeMenuOnSelect={true}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: primaryColor[0],
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: "20px"}}>
                                                <InputLabel htmlFor="debitType" className={classes.selectTitle}>
                                                    Select time
                                                </InputLabel>
                                                <TimePicker value={this.state.cronTimer} format={"HH:mm"} onChange={this.handleTimerValueChange} showSecond={false}/>
                                            </div>
                                        </GridItem>
                                        {
                                        !this.state.isEditing 
                                        ? ""
                                        : <GridItem xs={12} sm={12} md={12}>
                                            <div style={{marginTop: "20px"}}>
                                                <InputLabel htmlFor="debitType" className={classes.selectTitle}>
                                                {t('ENABLED')}
                                                </InputLabel>
                                                <CustomSwitch
                                                    className={classes.switchBase}
                                                    size="small"
                                                    value={enabled}
                                                    checked={enabled}
                                                    onChange={() => this.handleEnabledChange(enabled)}
                                                />
                                            </div>
                                        </GridItem>
                                        }
                                    </>
                                    :
                                    ""
                                    }
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.center} style={{ marginTop: 20 }}>
                                            <Button
                                                color="white"
                                                disabled={this.state.disableField}
                                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                                onClick={() => this.props.history.goBack()}
                                            >
                                                {t('BTN_CANCEL')}
                                            </Button>
                                            <Button
                                                color="primary"
                                                className={classes.buttonSpacing}
                                                onClick={() => this.saveDebit()}
                                                disabled={this.state.disableField}
                                            >
                                                {t('BTN_SAVE')}
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={false} sm={2} md={2} lg={3} />
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

DirectDebitForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(DirectDebitForm)));
