/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
//import Dashboard from "@material-ui/icons/Dashboard";
import ic_Settings from "@material-ui/icons/Settings";
import ic_Profile from "@material-ui/icons/Person";
import ic_Products from "@material-ui/icons/ShoppingCart";
import ic_BarChart from "@material-ui/icons/BarChart";
import ic_Payments from "@material-ui/icons/Payment";
import ic_Members from "@material-ui/icons/People";
import ic_Receipt from "@material-ui/icons/Receipt";
import ic_ViewList from "@material-ui/icons/ViewList";
import ic_App from "@material-ui/icons/Apps";
import ic_Automations from "@material-ui/icons/Apps";
import ic_Location from "@material-ui/icons/Place"
import ic_List from "@material-ui/icons/ListAlt"
import ic_Home from "@material-ui/icons/HomeOutlined";
import ic_Assets from "@material-ui/icons/Memory";
import ic_Time from "@material-ui/icons/AlarmOn";
import ic_Error from "@material-ui/icons/Report";
// import ic_Role from "@material-ui/icons/Face";
import ic_Activity from "@material-ui/icons/Class";
import ic_Discount from "@material-ui/icons/TrendingDown";
import ic_Integrations from "@material-ui/icons/Cloud";
import ic_Cash from "@material-ui/icons/AccountBalanceWallet";
import ic_Bank from "@material-ui/icons/AccountBalance";
import ic_Debit from "@material-ui/icons/CreditCardTwoTone";
import ic_Groups from "@material-ui/icons/GroupAdd";
import ic_PaymentMethods from "@material-ui/icons/Payment";

// components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import HomePage from "views/Home/Home";
import Stores from "./views/Stores/Stores";
import Menus from "./views/Menus/Menus";
import Products from "./views/Products/Products";
import Orders from "./views/Orders/Orders";
import Payments from "./views/Payments/Payments";
import QuickActionsStore from "./views/Stores/components/QuickActionsStore";
import QuickActionsMenu from "./views/Menus/components/QuickActionsMenu";
import QuickActionsZone from "./views/Zones/components/QuickActionsZone";
import Assets from "./views/Assets/Assets";
import TimeChecks from "./views/TimeChecks/TimeChecks";
// import Members from "./views/Members/Members";
import Categories from "./views/Categories/Categories";
import PriceBooks from "./views/PriceBooks/PriceBooks";
// import Roles from "./views/Roles/Roles";
import Applications from "./views/Applications/Applications";
import QuickActionsRole from "./views/Roles/components/QuickActionsRole";
import Activities from "./views/Activities/Activities";
import QuickActionsActivity from "./views/Activities/components/QuickActionsActivity";
import QuickActionsSession from "./views/Activities/components/QuickActionsSession";
import QuickActionsApplication from "./views/Applications/components/QuickActionsApplication";
import Profile from "./views/Profile/Profile";
import QuickActionsCategory from "./views/Categories/components/QuickActionsCategory";
import Discounts from "./views/Discounts/Discounts";
import QuickActionsDiscount from "./views/Discounts/components/QuickActionsDiscount";
import OrdersKanban from "./views/OrdersKanban/OrdersKanban";
import AccountContainer from "./views/AccountSettings/AccountContainer";
import ic_PaymentRequest from "@material-ui/icons/SwapHorizontalCircle";
import PaymentRequest from "./views/Payments/PaymentRequest";
import IntegrationContainer from "./views/Integrations/IntegrationContainer";
import QuickActionsMember from "./views/Members/components/QuickActionsMember";
import QuickActionsPaymentRequest from "./views/Payments/components/QuickActionsPaymentRequest";
import ic_Template from "@material-ui/icons/CollectionsBookmark";
import PaymentTemplates from "./views/Payment Template/PaymentTemplates";
import QuickActionsPaymentTemplate from "./views/Payment Template/components/QuickActionsPaymentTemplate";
import MemberContainer from "./views/Members/MemberContainer";
import ResourceNotFound from "./views/NotFound/ResourceNotFound";
import Cash from "./views/Cash/Cash";
import TransferFormContainer from "./views/Cash/components/Transfers/TransferFormContainer";
import CashAccountFormContainer from "./views/Cash/components/Accounts/CashAccountFormContainer";
import BlockDepositScreen from "./views/Cash/components/WalletAccountMovement/BlockDepositScreen";
import RevenueReconciliationDetails from "./views/RevenueReconciliation/RevenueReconciliationDetails";
import RevenueReconciliationScreen from "./views/RevenueReconciliation/RevenueReconciliationsScreen";
import Automations from "./views/Automation/Automations";
import MemberGroups from "./views/MemberGroups/MemberGroups";
import MemberGroupDetails from "./views/MemberGroups/component/MemberGroupDetails";
import MemberGroupAssignment from "./views/MemberGroups/component/MemberGroupAssignment";
import MemberPaymentMethods from "./views/Members/components/MemberPaymentMethods";
import DirectDebitsController from "./views/DirectDebit/DirectDebitsController";
import DebitDetailsController from "./views/DirectDebit/components/DebitDetailsController";
import DebitExecutionDetailsController from "./views/DirectDebit/components/execute/DebitExecutionDetailsController";
import DirectDebitForm from "./views/DirectDebit/components/DirectDebitForm";
import DirectDebitHistoryController from "./views/DirectDebit/components/DirectDebitHistoryController";


// Changed by Marlon (Added appType for filter routes and don't use commerce applications routes)
const dashboardRoutes = [
  {
    path: "/home",
    name: "HOME_TEXT",
    icon: ic_Home,
    component: HomePage,
    appType: "Default",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "ANALYTICS_TEXT",
    icon: ic_BarChart,
    component: DashboardPage,
    appType: "Default",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/stores",
    name: "STORES_TEXT",
    icon: ic_Location,
    component: Stores,
    appType: "Default",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/members",
    name: "MEMBERS_TEXT",
    icon: ic_Members,
    component: MemberContainer,
    appType: "Setup",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/cash",
    name: "CASH_TEXT",
    icon: ic_Bank,
    component: Cash,
    isTab: true,
    appType: "Default",
    layout: "/admin"
  },
  {
    path: "/reconciliations",
    name: "REVENUE_RECONCILIATION_TXT",
    icon: ic_Cash,
    component: RevenueReconciliationScreen,
    appType: "Default",
    layout: "/admin"
  },
  {
    path: "/payment-requests",
    name: "PAYMENT_REQUEST_TEXT",
    icon: ic_PaymentRequest,
    component: PaymentRequest,
    appType: "Payment",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/payment-templates",
    name: "PAYMENT_TEMPLATE_TEXT",
    icon: ic_Template,
    component: PaymentTemplates,
    appType: "Payment",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/orders",
    name: "ORDERS_TEXT",
    icon: ic_Receipt,
    component: Orders,
    appType: "Payment",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/payments",
    name: "TRANSACTIONS_TEXT",
    icon: ic_Payments,
    component: Payments,
    appType: "Payment",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/debits",
    name: "",
    icon: ic_Debit,
    component: DirectDebitsController,
    isTab: true,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/payment-methods",
    name: "PAYMENT_METHODS_TEXT",
    icon: ic_PaymentMethods,
    component: MemberPaymentMethods,
    isTab: true,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/member-groups",
    name: "MEMBER_GROUPS_TEXT",
    icon: ic_Groups,
    component: MemberGroups,
    isTab: true,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/member-group/:id",
    name: "MEMBER_GROUP_DETAILS_TEXT",
    icon: ic_Groups,
    component: MemberGroupDetails,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/member-group/:id/member-assignment",
    name: "MEMBER_GROUP_ASSIGNMENT_TEXT",
    icon: ic_Groups,
    component: MemberGroupAssignment,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/menus",
    name: "MENUS_TEXT",
    icon: ic_List,
    component: Menus,
    appType: "Commerce",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "PRODUCTS_TEXT",
    icon: ic_Products,
    component: Products,
    appType: "Commerce",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/orders-kanban",
    name: "ORDERS_KANBAN_TEXT",
    icon: ic_ViewList,
    component: OrdersKanban,
    appType: "Commerce",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/discounts",
    name: "DISCOUNTS_TEXT",
    icon: ic_Discount,
    component: Discounts,
    appType: "Commerce",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/applications",
    name: "APPLICATIONS_TEXT",
    icon: ic_App,
    component: Applications,
    appType: "Setup",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/assets",
    name: "ASSETS_TEXT",
    icon: ic_Assets,
    component: Assets,
    appType: "Setup",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/integrations",
    name: "CONNECT_TEXT",
    icon: ic_Integrations,
    component: IntegrationContainer,
    appType: "Setup",
    isTab: true,
    layout: "/admin"
  },
  // {
  //   path: "/automations",
  //   name: "AUTOMATIONS_TEXT",
  //   icon: ic_Automations,
  //   component: Automations,
  //   appType: "Setup",
  //   layout: "/admin"
  // },
  {
    path: "/automations",
    name: "AUTOMATIONS_TEXT",
    icon: ic_Automations,
    component: Automations,
    appType: "Setup",
    layout: "/admin"
  },
  {
    path: "/attendance",
    name: "ATTENDANCE_TEXT",
    icon: ic_Time,
    component: TimeChecks,
    appType: "Activity",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/company-settings",
    name: "SETTINGS_TEXT",
    icon: ic_Settings,
    component: AccountContainer,
    appType: "Default",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "PROFILE_TEXT",
    icon: ic_Profile,
    component: Profile,
    appType: "Default",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/categories",
    name: "CATEGORIES_TEXT",
    icon: ic_Products,
    component: Categories,
    appType: "Commerce",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/price-books",
    name: "PRICE_BOOKS_TEXT",
    icon: ic_Payments,
    component: PriceBooks,
    appType: "Commerce",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/activities",
    name: "ACTIVITIES_TEXT",
    icon: ic_Activity,
    component: Activities,
    appType: "Activity",
    isTab: true,
    layout: "/admin"
  },
  {
    path: "/quick-actions-store",
    name: "STORE_TEXT",
    component: QuickActionsStore,
    appType: "Setup",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-menu",
    name: "MENU_TEXT",
    component: QuickActionsMenu,
    appType: "Commerce",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-zone",
    name: "ZONE_TEXT",
    component: QuickActionsZone,
    appType: "Setup",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-role",
    name: "ROLE_TEXT",
    component: QuickActionsRole,
    appType: "Setup",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-discount",
    name: "DISCOUNTS_TEXT",
    component: QuickActionsDiscount,
    appType: "Setup",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-activity",
    name: "ACTIVITY_TEXT",
    component: QuickActionsActivity,
    appType: "Attendance",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-session",
    name: "SESSION_TEXT",
    component: QuickActionsSession,
    appType: "Attendance",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-application",
    name: "APPLICATION_TEXT",
    component: QuickActionsApplication,
    appType: "Setup",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/quick-actions-category",
    name: "CATEGORY_TEXT",
    component: QuickActionsCategory,
    appType: "Commerce",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/member-details/:id",
    name: "MEMBER_DETAILS_TXT",
    component: QuickActionsMember,
    appType: "Setup",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/payment-request-details/:id",
    name: "PAYMENT_REQUEST_DETAILS_TXT",
    component: QuickActionsPaymentRequest,
    appType: "Payment",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/payment-template-details/:id",
    name: "PAYMENT_TEMPLATE_DETAILS_TXT",
    component: QuickActionsPaymentTemplate,
    appType: "Payment",
    isTab: false,
    layout: "/admin"
  },
  {
    path: "/not-found",
    name: "",
    icon: ic_Error,
    component: ResourceNotFound,
    appType: "Default",
    layout: "/admin"
  },
  {
    path: "/wallet-account/:waId/transfer-form/:action",
    name: "TRANSFERS_TXT",
    icon: ic_Bank,
    component: TransferFormContainer,
    isTab: false,
    appType: "Payment",
    layout: "/admin"
  },
  {
    path: "/cash-account-form/:action",
    name: "ACCOUNTS_TXT",
    icon: ic_Bank,
    component: CashAccountFormContainer,
    isTab: false,
    appType: "Payment",
    layout: "/admin"
  },
  {
    path: "/wallet-account/:waId/deposit/:id",
    name: "",
    icon: ic_Receipt,
    component: BlockDepositScreen,
    isTab: false,
    appType: "Payment",
    layout: "/admin"
  },
  {
    path: "/reconciliation/:id",
    name: "",
    icon: ic_Receipt,
    component: RevenueReconciliationDetails,
    isTab: false,
    appType: "Payment",
    layout: "/admin"
  },
  {
    path: "/debit-form/new",
    name: "FORM_DEBIT_TEXT",
    icon: ic_Debit,
    component: DirectDebitForm,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/debit-form/:id",
    name: "FORM_DEBIT_TEXT",
    icon: ic_Debit,
    component: DirectDebitForm,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/debit/:id",
    name: "DEBITS_HISTORY_TEXT",
    icon: ic_Debit,
    component: DirectDebitHistoryController,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/debit/:id/prepare-sync",
    name: "DEBIT_SYNC_RESULT_DETAILS_TEXT",
    icon: ic_Debit,
    component: DebitDetailsController,
    appType: "DirectDebit",
    layout: "/admin"
  },
  {
    path: "/debit/:id/execution/:exId",
    name: "DEBIT_EXECUTION_DETAILS_TEXT",
    icon: ic_Debit,
    component: DebitExecutionDetailsController,
    appType: "DirectDebit",
    layout: "/admin"
  },
];

export default dashboardRoutes;
