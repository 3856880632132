import React from "react";
import {withRouter} from "react-router-dom";
import {withNotification} from "../../../components/HOC/Notification/NotificationHOC";
import {withTranslation} from "react-i18next";
import DirectDebitHistoryScreen from "./history/DirectDebitHistoryScreen";
import {getLastDirectDebitJobs, refreshPrivateServiceToken} from "../../../services/directDebitService";
import {getData, isEmpty, removeData, saveData} from "../../../utils/helpersFunctions";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {NOTIFICATION_DURATION_LARGE} from "../../../variables/coreConstants";


class DirectDebitHistoryController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingMore: false,
            isLoading: false,
            data: [],
            isDebitAutomatic: false,
            debitCronSchedule: "",
            enabled: false,
            retryDebitToken: 0
        }
    }


    componentDidMount() {
        this.onRequestLastDebitJobs();
    }

    onRequestLastDebitJobs = () => {
        const { showNotificationMessage, t, match } = this.props;

        this.setState({
            isLoading: true
        }, () => {
            getLastDirectDebitJobs(match.params.id).then(r => {
                let data = r.data.map(i => {
                    i.createdDate = !isEmpty(i.createdDate) ? new Date(parseInt(i.createdDate)).toLocaleString() : "---";
                    i.lastModifiedDate = !isEmpty(i.lastModifiedDate) ? new Date(parseInt(i.lastModifiedDate)).toLocaleString() : "---";
                    return i;
                });

                let debit = getData("debit");

                this.setState({
                    isLoading: false,
                    data: data,
                    isDebitAutomatic: debit.debitType && debit.debitType != "" && debit.debitType != "Manual",
                    debitCronSchedule: debit.cronSchedule ? debit.cronSchedule : "",
                    enabled: debit.enabled == "true",
                })
            }).catch(e => {
                if (!isEmpty(e.response)) {
                    if (this.state.retryDebitToken <= 2) {
                        refreshPrivateServiceToken(e.response, this.onRequestLastDebitJobs, (errorResponse) => {
                            this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                            showNotificationMessage(errorResponse.data.error.message, 'danger', NotificationErrorIcon,
                                NOTIFICATION_DURATION_LARGE, null);
                        });
                    } else {
                        this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                        showNotificationMessage(e.response.data.error.message, 'danger', NotificationErrorIcon,
                            NOTIFICATION_DURATION_LARGE, null);
                    }
                } else {
                    this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            });
        });
    }


    handlePrepareNew = () => {
        let debit = getData("debit");
        saveData("syncJobAvailable", true);
        removeData("isExecuted");
        removeData("syncJobId");
        removeData("syncSummary");
        removeData("debitExecution");

        this.props.history.push("/admin/debit/" + debit.id + "/prepare-sync");
    };

    handleUpdateCurrent = () =>{
        let debit = getData("debit");
        this.props.history.push("/admin/debit-form/" + debit.id );
    }

    handleSelect = (item) => {
        let debit = getData("debit");

        if (item["executed"] === "Yes" || item["executed"] === "Si") {
            saveData("syncJobAvailable", item["availableToExecute"]);
            saveData("syncJobId", item["id"]);
            saveData("isExecuted", true);
            removeData("syncSummary");
            saveData("debitExecution", {
                debitName: debit.name,
                executionJobId: item["id"],
                executionDate: item["createdDate"]
            });

            this.props.history.push("/admin/debit/" + debit.id + "/execution/" + item["id"]);
        } else {
            removeData("isExecuted");
            removeData("syncSummary");
            saveData("syncJobId", item["id"]);
            saveData("syncJobAvailable", item["availableToExecute"]);
            this.props.history.push("/admin/debit/" + debit.id + "/prepare-sync");
        }
    };

    awsCronToHuman(awsCron) {
        const cronParts = awsCron.split(" ");
        
        if (cronParts.length !== 6) {
            throw new Error("Invalid AWS cron expression. Expected 6 parts.");
        }
    
        const minute = parseInt(cronParts[0], 10) || 0;
        const hour = parseInt(cronParts[1], 10) || 0;
        const dayOfMonth = parseInt(cronParts[2], 10);
        const month = cronParts[3] !== "*" ? parseInt(cronParts[3], 10) - 1 : null; // AWS months are 1-based, JS uses 0-based
        const now = new Date();
    
        let nextExecution;
    
        if (month !== null) {
            // If a specific month is provided, use it
            const currentYear = now.getFullYear();
            nextExecution = new Date(currentYear, month, dayOfMonth, hour, minute);
            if (nextExecution < now) {
                nextExecution.setFullYear(currentYear + 1); // Move to next year if it's in the past
            }
        } else {
            // If no month is specified, find the next valid execution
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
    
            if (now.getDate() < dayOfMonth) {
                nextExecution = new Date(currentYear, currentMonth, dayOfMonth, hour, minute);
            } else {
                let nextMonth = currentMonth + 1;
                let nextYear = currentYear;
                if (nextMonth > 11) {
                    nextMonth = 0;
                    nextYear += 1;
                }
                nextExecution = new Date(nextYear, nextMonth, dayOfMonth, hour, minute);
            }
        }
    
        const diffTime = nextExecution - now;
        const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const timeStr = nextExecution.toTimeString().split(" ")[0];
    
        return `The task will be executed in ${days} days and at ${timeStr}.`;
    }


    render() {
        const { data, isLoading } = this.state;

        return <>
            {/*this.state.isDebitAutomatic && this.state.debitCronSchedule != "" ? <p>{this.awsCronToHuman(this.state.debitCronSchedule)}</p> :""*/}
            <DirectDebitHistoryScreen
                data={data}
                isLoading={isLoading}
                onPrepareNew={this.handlePrepareNew}
                onUpdateCurrent={this.handleUpdateCurrent}
                onSelectRow={this.handleSelect}
                isDebitAutomatic={this.state.isDebitAutomatic}
            />
        </>;
    };

}


export default withRouter(withNotification(withTranslation()(DirectDebitHistoryController)));
