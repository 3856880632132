import React from "react";
import PropTypes from "prop-types";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import tableViewStyle from "../../../../assets/jss/custom-components/tableViewStyle";
import {withTranslation} from "react-i18next";
import {Backdrop, CircularProgress} from "@material-ui/core";
import "../../../../assets/css/wijmo-main-flex-grid.css";
import TableGridWijmo from "../../../../components/WijmoTableGrid/TabledGridWijmo";



class DirectDebitHistoryScreen extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         timeUpdate: new Date(),
    //         timeSince: timeSince(new Date(), props.t),
    //     };
    // };
    //
    // componentDidMount() {
    //     window.setInterval(() => {
    //         this.setState({
    //             timeSince: timeSince(this.state.timeUpdate, this.props.t)
    //         });
    //     }, 30000);
    // }


    render() {
        const { classes, t, data, isLoading, onPrepareNew, onUpdateCurrent , onSelectRow, handleInitGrid, isDebitAutomatic } = this.props;

        return (
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color={"inherit"} />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <TableGridWijmo
                            tableName={'direct_debit_history'}
                            title={t('DEBITS_HISTORY_TEXT')}
                            exportFileName={t('DEBITS_HISTORY_TEXT')}
                            // handleLoadMore={() => {}}
                            isLoading={isLoading}
                            data={data}
                            actions={[
                                { label: (isDebitAutomatic ? t('BTN_UPDATE_CURRENT') : t('BTN_PREPARE_NEW')),
                                  function: (isDebitAutomatic ? onUpdateCurrent : onPrepareNew),
                                  isLoading: false,
                                  disabled: false,
                                  type: "simple",
                                },
                                { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                            ]}
                            controls={[]}
                            headers={[
                                { label: "No", name: "recordNum", cssClass: "cell-number", isReadOnly: true, width: 50, onSelect: onSelectRow, isCustomCell: true, clickable: true },
                                { label: t('TH_EXECUTED'), name: "executed", isReadOnly: true, width: 120 },
                                { label: t('TH_CREATED_AT'), name: "createdDate", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('EXECUTED_DATE'), name: "lastModifiedDate", isReadOnly: true, width: "*", isCustomCell: false }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

DirectDebitHistoryScreen.propTypes = {
    classes: PropTypes.object,
    handleCheckRow: PropTypes.func,
    handleSelectColumn: PropTypes.func,
    onPrepareNew: PropTypes.func,
    onSelectRow: PropTypes.func,
    onSearch: PropTypes.func,
    onRefresh: PropTypes.func,
    onExecuteDebit: PropTypes.func,
    onCloseExecutionModal: PropTypes.func,
    directDebit: PropTypes.object,
    data: PropTypes.array,
    dataItemCount: PropTypes.number,
    excludedItems: PropTypes.array,
    summary: PropTypes.object,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    lastPage: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    isRequesting: PropTypes.bool,
    debitInfoLoaded: PropTypes.bool,
    executionEnabled: PropTypes.bool,
    syncAvailable: PropTypes.bool,
    refreshAvailable: PropTypes.bool,
    showExecutionModal: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleLastPage: PropTypes.func,
    handleFirstPage: PropTypes.func,
    handlePreviousPage: PropTypes.func,
    handleNextPage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleCloseLiveProcess: PropTypes.func,
    handleLoadMore: PropTypes.func,
    handleRequestLastDebitJobs: PropTypes.func,
    handleRecentJob: PropTypes.func,
    handleInitGrid: PropTypes.func,
    showLiveProcess: PropTypes.bool,
    tasks: PropTypes.array
}

export default withRouter(withStyles(tableViewStyle)(withTranslation()(DirectDebitHistoryScreen)));